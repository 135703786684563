<template>
  <b-modal
    cancel-variant="outline-secondary"
    :cancel-title="$t('button.close')"
    centered
    no-close-on-backdrop
    hide-footer
    size="xl"
    v-model="isShow"
  >
    <validation-observer ref="reservePriceForm">
      <n-form-confirmation
        ref="confirmForm"
        key="updateForm"
        :form="$refs.updateForm"
        ok-variant="success"
        @submit="save"
      >
        <b-row class="mb-1">
          <b-col cols="12" class="text-right">
            <n-button-loading
              type="button"
              variant="success"
              :loading="loading"
              @submit="submit"
            >
              {{ $t("button.save") }}
            </n-button-loading>
          </b-col>
        </b-row>

        <n-table
          ref="table"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="query.page"
          :per-page="query.limit"
          :total="total"
          :resource="resource"
          :route="route"
        >
          <template #cell(inspectedAt)="data">
            <span v-if="data.item.inspectedAt">
              {{ data.item.inspectedAt | formatDate }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(reservePrice)="data">
            <validation-provider
              #default="{ errors }"
              :vid="`price-${data.index}`"
              :name="`field.price`"
              :rules="`decimal:2`"
            >
              <n-currency-input
                class="table-input"
                :id="`price-${data.index}`"
                :name="`price-${data.index}`"
                v-model="data.item.reservePrice"
                prefix="$"
                :class="{
                  'is-invalid': errors.length > 0,
                  'bg-red': !data.item.reservePrice,
                }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </template>
          <template #cell(buyNowPrice)="data">
            <validation-provider
              #default="{ errors }"
              :vid="`buy-now-price-${data.index}`"
              :name="`field.price`"
              :rules="`decimal:2`"
            >
              <n-currency-input
                class="table-input"
                :id="`price-${data.index}`"
                :name="`price-${data.index}`"
                v-model="data.item.buyNowPrice"
                prefix="$"
                :class="{
                  'is-invalid': errors.length > 0,
                }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </template>
        </n-table>
      </n-form-confirmation>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import NCurrencyInput from "@/components/NCurrencyInput";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";

const VehicleRepository = Repository.get("vehicle");

export default {
  components: {
    BModal,
    BRow,
    BCol,

    NTable,
    NCurrencyInput,
    NButtonLoading,
    NFormConfirmation,
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      isShow: false,
      loading: false,
      items: [],
      total: 0,
      query: {
        page: 1,
        limit: 10,
      },
      selected: [],
    };
  },
  methods: {
    show(selected) {
      this.selected = selected;
      this.items = [];
      this.total = 0;
      this.query.limit = this.selected.length;
      this.query.page = 1;
      this.getData();

      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    list(page = 1) {
      this.query.page = page;
      this.getData();
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
        searchFields: [],
        query: [
          {
            searchby: "id",
            searchoperator: "in",
            search: this.selected,
          },
        ],
      };
      VehicleRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs.reservePriceForm.validate().then((success) => {
        if (success) {
          this.$refs.confirmForm.confirm();
        }
      });
    },
    save() {
      this.loading = true;
      let vehicles = [];
      this.items.forEach((element) => {
        if (element.reservePrice) {
          vehicles.push({
            id: element.id,
            price: element.reservePrice,
            buyNowPrice: element.buyNowPrice,
          });
        }
      });
      if (vehicles.length) {
        VehicleRepository.updateReservePrice({ vehicles: vehicles })
          .then((response) => {
            this.hide();
            this.$emit("success");
          })
          .catch((error) => {
            if (error.response?.status == 422) {
              this.$refs.reservePriceForm.setErrors(
                error.response?.data?.errors
              );
            }
          })
          .then(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
  },
  setup() {
    const fields = [...TableFields];
    const resource = "vehicle";
    const route = "vehicle";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>