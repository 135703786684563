<template>
  <div>
    <b-modal
      cancel-variant="outline-secondary"
      :cancel-title="$t('button.close')"
      centered
      no-close-on-backdrop
      hide-footer
      size="lg"
      v-model="isShow"
    >
      <validation-observer ref="updateForm" #default="{ invalid }">
        <n-form-confirmation
          ref="confirmForm"
          key="updateForm"
          :form="$refs.updateForm"
          ok-variant="success"
          :disabled="invalid"
          @submit="save"
        >
          <n-input :key="`input-${key}`" :fields="formInput" v-model="data">
            <template #id="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="item.field.label"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-single-select
                    v-model="data[item.field.key]"
                    :ref="item.field.key"
                    :name="item.field.key"
                    :options="options"
                    :selection-key="item.field.selectionKey"
                    :selection-label="item.field.selectionLabel"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                  </n-single-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="button"
                variant="success"
                :loading="loading"
                @submit="submit"
              >
                {{ $t("button.save") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BRow, BCol, BFormGroup } from "bootstrap-vue";
import FormInput from "./formInput";
import NInput from "@/components/NInput";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading";
import NSingleSelect from "@/components/NSingleSelect";
import moment from "moment";

const AuctionScheduleRepository = Repository.get("auctionSchedule");

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,

    NFormConfirmation,
    NInput,
    NButtonLoading,
    NSingleSelect,
  },
  watch: {
    "data.type": function (value) {
      this.updateTitle(value);
      return;
    },
  },
  data() {
    return {
      key: 1,
      isShow: false,
      loading: false,
      data: {},
      id: null,
      options: [],
    };
  },
  methods: {
    show(vehicleIds) {
      this.data = {
        id: null,
        vehicleIds: vehicleIds,
      };
      this.key++;
      this.isShow = true;
      this.getData();
    },
    hide() {
      this.isShow = false;
    },
    success() {
      this.$emit("success");
      this.hide();
    },
    getData() {
      this.loading = true;
      const params = {
        limit: 1000,
        order: "startDate",
        sort: "asc",
        query: [
          {
            searchby: "status",
            searchoperator: "=",
            search: this.scheduleState.RUNNING,
          },
        ],
      };
      AuctionScheduleRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.options = [...data.list];
            this.options = this.options.map((element) => {
              return {
                id: element.id,
                bidDate: `${element.location} - ${element.zone} (${moment(element.startDate).format(
                  "DD-MMMM-YYYY h:mm A"
                )})`,
              };
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs.updateForm.validate().then((success) => {
        if (success) {
          this.$refs.confirmForm.confirm();
        }
      });
    },
    save() {
      this.loading = true;
      AuctionScheduleRepository.addVehicles(this.data)
        .then((response) => {
          this.success();
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.updateForm.setErrors(error.response?.data?.errors);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const formInput = [...FormInput];

    return {
      formInput,
    };
  },
};
</script>