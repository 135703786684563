export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'vinNumber',
    sortable: true,
    sortField: 'vinNumber',
    label: 'field.vinNumber',
    thClass: 'text-left',
    tdClass: 'text-left',
    hideToggle: true,
  },
  {
    key: 'year',
    label: 'field.year',
    thClass: 'text-left',
    tdClass: 'text-left',
    hideToggle: true,
  },
  {
    key: 'brandName',
    label: 'field.brand',
    thClass: 'text-left',
    tdClass: 'text-left',
    hideToggle: true,
  },
  {
    key: 'model',
    label: 'field.model',
    thClass: 'text-left',
    tdClass: 'text-left',
    hideToggle: true,
  },
  {
    key: 'engineCapacity',
    label: 'field.engineCapacity',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'transmission',
    label: 'field.transmission',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'zone',
    label: 'field.zone',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'reservePrice',
    label: 'field.reservePrice',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'buyNowPrice',
    label: 'field.buyNowPrice',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'inspectedAt',
    label: 'field.inspectedAt',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
];
